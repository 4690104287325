import React from 'react'

type Props = {
  pageName: string
}
const PageBreadcumb:React.FC<Props> = ({pageName}) => {
  return (
    <div className="breadcumb-area">
      <div className="container">
        <div className="row">
          <div className="col-md-12 txtc  text-center ccase">
            <div className="brpt">
              <h2>{pageName}</h2>
            </div>
            <div className="breadcumb-inner">
              <ul>
                <li>
                  You are currently here! <i className="icofont-thin-right"></i>{' '}
                </li>
                <li>
                  <a href="/" rel="v:url" property="v:title">
                    Home
                  </a>
                </li>
                <li>
                  <i className="icofont-thin-right"></i>
                </li>
                <li>
                  <span className="current">{pageName}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageBreadcumb
